import _store from 'store';
import { type ReactNode } from 'react';
import type { ServerError } from '@apollo/client';
import { ApolloClient, ApolloProvider, ApolloLink, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { GoogleMapsAPIProvider } from '@pledge-earth/web-components';
import { GlobalToastRegion } from '@pledge-earth/product-language';

import { ReduxSagaProvider } from '../store/provider';
import { logout, refreshToken } from '../services/user/userService';
import { Localization } from '../localization';
import { store } from '../store/store';
import { apiVersionChanged } from '../store/internalSettings/reducers';
import { getIntl } from '../locales/intl';
import { showErrorToast, showInfoToast } from '../utils/toast';

import { FlagProvider } from './FlagProvider';

const responseVersionHeaderLink = new ApolloLink((operation, forward) =>
  forward(operation).map((serverResponse) => {
    const context = operation.getContext();

    const {
      response: { headers },
    } = context;

    const responseHeader = headers.get('X-Pledge-Version');

    // update internal settings with new backend version
    store.dispatch(apiVersionChanged({ apiVersion: responseHeader }));
    return serverResponse;
  }),
);

// Set up Apollo Client
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }): any => {
  const { formatMessage } = getIntl();
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      /* eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions -- eslint onboarding */
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

      if (extensions?.code === 'CLIENT_SUBSCRIPTION_NOT_ACTIVE') {
        // We remove the access_token from local storage to refresh the session
        _store.remove('accessToken');
        // When reloading the page
        window.location.reload();
      }

      if (process.env.NODE_ENV === 'development') {
        if (extensions?.code === 'TEST_PROTECTED_ERROR') {
          showErrorToast({
            description: formatMessage({ id: 'environment.test.protected' }),
          });
        } else if (extensions?.code === 'INTERNAL_SERVER_ERROR') {
          showErrorToast({
            description: formatMessage({ id: 'error' }),
          });
        } else {
          showErrorToast({
            description: message,
          });
        }
      }
    });
  }

  if (networkError) {
    if ((networkError as ServerError).statusCode === 401) {
      if (!_store.get('rememberMe')) {
        showInfoToast({
          description: formatMessage({ id: 'auth.signin.expired' }),
        });

        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
        logout();
        window.location.pathname = '/auth/sign-in';
      } else if (_store.get('rememberMe')) {
        return fromPromise(refreshToken()).flatMap((accessToken) => {
          const oldHeaders = operation.getContext().headers;
          // modify the operation context with a new token
          operation.setContext({
            headers: {
              ...oldHeaders,

              authorization: `Bearer ${accessToken}`,
            },
          });

          // retry the request, returning the new observable
          return forward(operation);
        });
      }
    }
  }

  return undefined;
});

const headerLink = setContext((_request, previousContext) => ({
  headers: {
    ...previousContext.headers,

    authorization: _store.get('accessToken') ? `Bearer ${_store.get('accessToken')}` : '',
  },
}));

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  headers: {
    'apollo-require-preflight': 'true',
  },
});

const removeTypenameLink = removeTypenameFromVariables();

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, headerLink, removeTypenameLink, responseVersionHeaderLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          client_integration_logs: {
            keyArgs: ['public_id'],
            merge: (existing = {}, incoming = {}) => ({
              ...incoming,
              streams: [...(existing.streams || []), ...incoming.streams],
            }),
          },
          invoices: {
            keyArgs: ['id'],
            merge: (existing = {}, incoming = {}) => ({
              ...incoming,
              items: [...(existing.items || []), ...incoming.items],
            }),
          },
        },
      },
      ClientUserTip: { keyFields: ['type'] },
      // emissions have two keys, id and public_id. Apollo can only use one or both, not either. We almost always query by public_id so we choose that as the key here.
      // see: https://github.com/apollographql/apollo-feature-requests/issues/75
      Emission: { keyFields: ['public_id'] },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export function Provider({ children }: { children: ReactNode }) {
  return (
    <ApolloProvider client={client}>
      <ReduxSagaProvider store={store}>
        <FlagProvider>
          <Localization>
            <GoogleMapsAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ''}>
              {children}
              <GlobalToastRegion />
            </GoogleMapsAPIProvider>
          </Localization>
        </FlagProvider>
      </ReduxSagaProvider>
    </ApolloProvider>
  );
}
